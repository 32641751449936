import React from 'react'
import { Container, Box, Link as A } from '@hackclub/design-system'
import Layout from 'components/Layout'
import { Link } from 'gatsby'
import Nav from 'components/Nav'
import {
  Headline,
  AboutHeader,
  SectionLead,
  Ultraline,
  AboutCols
} from 'components/Content'
import Start from 'components/Start'
import Footer from 'components/Footer'
import Social from '../components/Socialicons'
import { graphql } from 'gatsby'

A.link = A.withComponent(Link)

const title = 'About - Coding Sastra'

export default ({ data }) => (
  <Layout
    title={title}
    desc={data.site.siteMetadata.description}
    keywords={data.site.siteMetadata.keywords}
    path="/about/"
  >
    <Nav />
    <Social />
    <AboutHeader>
      <Container width={1} maxWidth={56} py={[8]} px={3} align="center">
        <Ultraline children="About us" />
      </Container>
    </AboutHeader>
    <Headline align="center" fontSize={5} mt={4} color="green.7">
      "Believe in your passion, it drives your career."
    </Headline>
    <Container maxWidth={70} py={[1]} px={1} align="left">
      <AboutCols cols="1fr 3fr" mx={-3} mt={[4, 5, 6]}>
        <Headline color="red.5" mt={3} pl={4}>
          WHO WE ARE
        </Headline>
        <Box p={2}>
          <Headline fontSize={4} mb={3} color="fuschia.7" mt={3} pl={2}>
            TAKEOFF
          </Headline>
          <SectionLead pl={[3, 0]} pr={3} mb={0}>
            It was the year 1998 when a freshly baked IIT’ian (from the
            prestigious IIT Delhi) Mr Varma flew to the USA, straight out of
            college, to join his 1st job at a Fortune 500 company as ‘software
            programmer’. <br />
            During his stay in the USA for 2 decades, that he witnessed a wide
            range of emerging tech, platforms & got equipped with them.
            <br /> His keen interest & expertise in such vital technologies,
            game him an opportunity to serve some of the leading brands of the
            USA (mostly Fortune 500 companies), which eventually paved his path
            to server a leading company as a CTO.
          </SectionLead>
          <Headline fontSize={4} mb={0} color="fuschia.7" mt={3} pl={2}>
            AN ARRIVAL - FOR A NOBLE CAUSE
          </Headline>
          <SectionLead pl={[3, 0]} pr={3} mb={0} mt={[2, 2, 3]}>
            It was during one of this recent visits to India in 2016, that he
            discovered the dearth of skilled professionals in the market and
            felt the need to contribute his part. to bridge the skill gap in the
            market This eventually lead to a thought of. sharing his huge &
            abundant experience in the ‘US IT Sector’ (for almost 20 yrs), with
            the current youth, to equip them with the right skill sets and make
            them industry ready for a glorious career.
          </SectionLead>
          <Headline fontSize={4} mb={0} color="fuschia.7" pl={2}>
            GIVING BACK TO THE COMMUNITY
          </Headline>
          <SectionLead pl={[3, 0]} pr={3} mb={0} mt={[2, 2, 3]}>
            Thus, in 2017, ‘Coding Sastra’, a ‘modern-age gurukul’ was founded
            by Mr. Varma (the same young & dynamic IITian who flew to the USA
            almost 20 years ago) with 2 main objectives:
            <br />
            1. To train the most vital & industry demanding skill sets to
            students, to lay the foundation to their fabulous career <br />
            2. To provide continuing education to industry professionals on
            updating themselves with the latest technologies to keep themselves
            competitive.
          </SectionLead>
        </Box>
      </AboutCols>
      <AboutCols cols="1fr 3fr" mx={-3} mt={[4, 5, 6]}>
        <Headline color="fuschia.5" mt={3} pl={4}>
          WHAT WE DO
        </Headline>
        <Box p={2}>
          <SectionLead pl={[3, 0]} pr={3} mb={0}>
            ‘CODING SASTRA’ is an expert level ‘skill development center’
            started by industry veterans, having a solid 20 year experience in
            the USA in multiple technologies. It was started as an initiative to
            provide ‘qualitative’ & ‘affordable’ training to both freshers &
            working professionals on some of the most cutting edge technologies,
            which are in utmost demand in the current market. In the process,
            we’ve been consistently successful in our attempt to, train & place
            freshers/professionals (like you) in highly rewarding careers with
            some of the top corporate companies.
          </SectionLead>
          <Headline fontSize={4} mb={0} color="red.6" pl={2}>
            SKILLED PROFESSIONALS
          </Headline>
          <SectionLead pl={[3, 0]} pr={3} mb={0} mt={[2, 2, 3]}>
            The Need & The Gap In Global Market: There is a huge shortage of
            skilled professionals globally, who are adept in the most in-demand
            technologies. And companies around the world are in a desperate &
            constant search for such professionals, offering them huge pay
            packages.
          </SectionLead>
          <Headline fontSize={4} mb={0} color="red.6" pl={2}>
            TECHNOLOGY TRAINING{' '}
          </Headline>
          <SectionLead pl={[3, 0]} pr={3} mb={0} mt={[2, 2, 3]}>
            The Importance & The Need To Choose The Right Option: The above
            mentioned factors prove the need & create the urgency of being
            skillful at the most happening technologies, to grab successful &
            rewarding career opportunities. And, this is where a right platform
            is needed to help you, in two of the most important tasks : 1.
            Educating you with the right skill sets 2. Launching you into the
            corporate world with ‘The Best’ opportunity But, making a bad
            decision in choosing the right platform for you, would result in a
            huge loss of your most valuable resources : ‘time’ & ‘money’.
          </SectionLead>
        </Box>
      </AboutCols>
      <AboutCols cols="1fr 3fr" mx={-3} mt={[4, 5, 6]}>
        <Headline color="red.5" mt={4} pl={4}>
          OUR MISSION
        </Headline>
        <SectionLead pl={[3, 0]} pr={3} mb={0}>
          Coding Sastra’s mission is to transform lives by teaching people of
          all backgrounds the technical and interpersonal skills used in
          software development so they can thrive in the IT industry. The vision
          for our company is to build a community of confident and passionate
          people with latest technologies to have a great career here.
        </SectionLead>
      </AboutCols>
    </Container>
    <Start mt={3} />
    <Footer mt={5} dark />
  </Layout>
)

export const query = graphql`
  query aboutMetaData {
    site {
      siteMetadata {
        description
        keywords
      }
    }
  }
`
