import React from 'react'
import styled from 'styled-components'
import { Box, LargeButton, Sheet, theme } from '@hackclub/design-system'
import { Subhline, Lead } from 'components/Content'
import { Link } from 'gatsby'

const Base = styled(Sheet).attrs({ maxWidth: 72 - 2 })`
  background-color: ${theme.colors.red[5]};
  background-image: ${theme.gradient('orange.5', 'red.5', 'red.6')};
  display: grid;
  grid-gap: ${theme.space[4]}px;
  align-items: center;
  ${theme.mediaQueries.md} {
    grid-gap: ${theme.space[5]}px;
    grid-template-columns: 2fr 1fr;
  }
`
const ApplyButton = styled(LargeButton).attrs({
  chevronRight: true
})`
  text-transform: uppercase;
  background-image: ${theme.gradient('primary', 'success')};
`

LargeButton.link = LargeButton.withComponent(Link)

const Start = ({ buttonProps = {}, ...props }) => (
  <Base px={[2, 4, 5]} py={[4, 5, 6]} my={4} {...props}>
    <Box align={['center', null, 'right']}>
      <Subhline color="white" mb={3}>
        Learn To Become a Software Engineer in 16 weeks.
      </Subhline>
      <Lead color="red.0" fontSize={[3, 4]} mb={2}>
        Immersive training.
      </Lead>
      <Lead color="red.0" fontSize={[3, 4]}>
        Powerful Learning Tools.
      </Lead>
    </Box>
    <Box align={['center', null, 'left']}>
      <ApplyButton
        href="/contact"
        children="Apply to Coding Sastra"
        mt={4}
        chevronright
      />
    </Box>
  </Base>
)

export default Start
